import { RichTextDocument } from "@hex/common";
import {
  Plate,
  PlateContent,
  PlateContentProps,
  PlateEditor,
  PlatePlugin,
  PlatePluginComponent,
  PlateProps,
  createPlugins,
  focusEditor,
} from "@udecode/plate-common";
import React, { CSSProperties, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";

import { useScrollOffset } from "../../hooks/useScrollOffset";
import { useUniqueId } from "../../hooks/useUniqueId";
import { useSelector } from "../../redux/hooks.js";
import { selectProjectSearchState } from "../../redux/slices/logicViewSlice.js";
import { MarkdownSize } from "../../theme/common/theme.js";

import { getRenderElement } from "./elementRenderers.js";
import { renderLeaf } from "./leafRenderer";
import {
  PlateRichTextContextProvider,
  usePlateRichTextToolbarMode,
} from "./PlateRichTextContext";
import { createSearchHighlightComponent } from "./plugins/createSearchHighlightPlugin.js";
import { createBasicPlugins } from "./plugins/RichTextBasicPlugins";
import {
  createElementPlugins,
  createElementPluginsComponents,
} from "./plugins/RichTextElementPlugins.js";
import {
  createEmojiPlugin,
  createEmojiPluginComponents,
} from "./plugins/RichTextEmojiPlugin";
import { createMarkPlugins } from "./plugins/RichTextMarksPlugin";
import {
  createMentionPlugin,
  createMentionPluginComponents,
} from "./plugins/RichTextMentionPlugin";
import { RichTextToolbarMode } from "./toolbar/RichTextToolbarMode";

export interface PlateRichTextEditorProps {
  allowScroll?: boolean;
  className?: string;
  allowedHotkeys?: string;
  // Plate keeps track of all the editors in memory in a map and id is used
  // as the key for setting/getting.
  // If not provided, will default to a component-generated unique id
  id?: string;
  initialValue?: PlateProps<RichTextDocument>["initialValue"];
  readOnly?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  tabIndex?: number;
  plugins?: {
    plugins: readonly PlatePlugin[];
    components?: Record<string, PlatePluginComponent>;
  };
  onChange?: (richText: RichTextDocument) => void;
  size?: MarkdownSize;
  children?: React.ReactNode;
  // Disables plugin for rich text formatting (bold, italic, underline, strikethrough).
  // Primary use case is for the magic prompt bar, for which we want to take advantage
  // of @-mention support but don't need rich formatting.
  richTextFormatting?: boolean;
  placeholderPadding?: CSSProperties["padding"];
  shouldCreateMentionPlugin?: boolean;
}

export const PlateRichTextEditor: React.ComponentType<PlateRichTextEditorProps> =
  React.memo(function PlateRichTextEditor(props) {
    return (
      <PlateRichTextContextProvider>
        <PlateRichTextEditorInternal {...props} />
      </PlateRichTextContextProvider>
    );
  });

const PlateRichTextEditorInternal: React.ComponentType<PlateRichTextEditorProps> =
  React.memo(function PlateRichTextEditorInternal({
    allowScroll = true,
    allowedHotkeys,
    autoFocus,
    children,
    className,
    id,
    initialValue,
    onChange,
    placeholder,
    placeholderPadding = "0",
    plugins: propPlugins,
    readOnly,
    richTextFormatting = true,
    shouldCreateMentionPlugin = true,
    size,
    tabIndex,
  }) {
    const editorRef = useRef<PlateEditor<RichTextDocument> | null>(null);
    const scrollOffset = useScrollOffset();
    const projectSearchState = useSelector(selectProjectSearchState);
    const editor = editorRef.current;
    const [toolbarMode] = usePlateRichTextToolbarMode();
    const uniqueId = useUniqueId();

    const plugins = useMemo(() => {
      // We pass in <any, any, any> here for the Plate plugin to allow for different plugins to have different 'Plate.Value' options / props.
      const pluginList: PlatePlugin<any, any, any>[] = [
        ...createBasicPlugins(),
        ...(richTextFormatting ? createMarkPlugins() : []), // comes before the mention plugin as keyboard event
        ...(richTextFormatting ? createElementPlugins() : []),
        // and mention plugin swallows ESCAPE key events
        // see: https://github.com/udecode/plate/blob/main/packages/nodes/mention/src/handlers/mentionOnKeyDownHandler.ts#L15-L22
        ...(propPlugins?.plugins ?? []),
        ...(readOnly || !shouldCreateMentionPlugin
          ? []
          : [createMentionPlugin()]),
        ...(readOnly ? [] : [createEmojiPlugin()]),
      ];

      // NOTE: handlers are called serially in order of plugins. Consider the ordering when adding in new plugins.
      return createPlugins(pluginList, {
        components: {
          ...createEmojiPluginComponents(),
          ...createMentionPluginComponents(),
          ...(richTextFormatting ? createElementPluginsComponents() : {}),
          ...propPlugins?.components,
          ...createSearchHighlightComponent(),
        },
      });
    }, [
      propPlugins?.components,
      propPlugins?.plugins,
      readOnly,
      richTextFormatting,
      shouldCreateMentionPlugin,
    ]);

    const renderElement = useMemo(() => getRenderElement(), []);

    // When projectSearchState changes, we force a redecoration of the current editor for highlight style changes.
    // Imperatively calling redecorate here is more performant than passing through the projectSearchState as a prop as that would risk re-constructing all plugins
    // anytime the project search term changes. This keeps the same reference to each plugin, and just redecorates on change.
    useEffect(() => {
      editorRef?.current?.redecorate();
    }, [projectSearchState]);

    const editableProps: PlateContentProps = useMemo(
      () => ({
        tabIndex,
        placeholder: placeholder,
        "data-mouse-trap-allowlist": allowedHotkeys,
        style: {
          overflow: allowScroll ? "auto" : "hidden",
        },
        readOnly,
        renderLeaf: renderLeaf,
        renderElement,
        renderPlaceholder: ({ attributes, children: placeholderChildren }) => {
          const { style, ...others } = attributes;
          return (
            <span
              data-textid="placeholder"
              {...others}
              style={{
                ...style,
                padding: placeholderPadding,
              }}
            >
              {placeholderChildren}
            </span>
          );
        },
        className: `${className} ${readOnly ? "readOnly" : ""}`,
      }),
      [
        tabIndex,
        placeholder,
        placeholderPadding,
        allowedHotkeys,
        allowScroll,
        readOnly,
        renderElement,
        className,
      ],
    );

    const editorId = id ?? uniqueId;

    useEffect(() => {
      if (editableProps.readOnly && initialValue != null && editor) {
        editor.children = initialValue;
      }
    }, [initialValue, editableProps.readOnly, editor]);

    useEffect(() => {
      if (autoFocus && editor) {
        const tId = setTimeout(() => focusEditor(editor), 0);
        return () => clearTimeout(tId);
      }
    }, [editor, autoFocus]);

    return (
      <Container $scrollOffset={scrollOffset} $size={size}>
        <Plate<RichTextDocument>
          editorRef={editorRef}
          id={editorId}
          initialValue={initialValue}
          plugins={plugins}
          onChange={
            // disable onChange (thereby pause persisting value via the onChange handler)
            // when link mode is enabled on the toolbar because when we're in the toolbar
            // link mode, we add a 'selection' mark to visualize the user's text selection
            // as the browser's native text selection is removed when the focus is moved
            // from the editor to the link input field
            toolbarMode === RichTextToolbarMode.LINK ? undefined : onChange
          }
        >
          <PlateContent {...editableProps} />
          {children}
        </Plate>
      </Container>
    );
  });

export const Container = styled.div<{
  $size?: MarkdownSize;
  $scrollOffset?: number | null;
}>`
  ${({ $size, theme }) => theme.markdownStyles($size ?? "default")}
  flex: 1 1 auto;

  word-break: break-word;

  ${(props) =>
    props.$scrollOffset
      ? `
    a.heading-link {
      scroll-margin-top: ${props.$scrollOffset}px;
    }`
      : ``}
`;
