import React from "react";
import styled, { useTheme } from "styled-components";

import { HexButton, HexTooltip } from "../../../hex-components";
import { useTerminology } from "../../../hooks/useTerminology";
import { StatusLabel } from "../../common/labels/StatusLabel";
import { Heading } from "../../Heading";
import {
  DataframeIcon,
  EyeOpenIcon,
  StarActiveIcon,
  TableCellIcon,
} from "../../icons/CustomIcons";

import {
  RichTextDataframeMentionData,
  RichTextTableMentionData,
} from "./RichTextMentionPlugin";

const Wrapper = styled.div`
  padding: 10px;
  max-width: 360px;
  min-width: 200px;
  width: max-content;
`;
const SchemaPath = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.MONO};
  font-size: 10px;
  font-weight: ${({ theme }) => theme.fontWeight.NORMAL};
  color: ${({ theme }) => theme.fontColor.MUTED};
`;

const TitleRow = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  white-space: nowrap;
  & > * {
    margin-right: 5px;
  }
`;

const TableName = styled.span`
  display: inline;
  transform: none;
  font-family: ${({ theme }) => theme.fontFamily.MONO};
  font-weight: ${({ theme }) => theme.fontWeight.NORMAL};
  white-space: normal;
  overflow: hidden;
  overflow-wrap: break-word;
`;

const StyledStatusLabel = styled(StatusLabel)`
  transform: translateY(3px);
`;

const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  color: ${({ theme }) => theme.fontColor.MUTED};
  line-height: ${({ theme }) => theme.lineHeight.SMALL};
  margin-bottom: 10px;

  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  overflow: hidden;
`;

const Columns = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.MONO};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  color: ${({ theme }) => theme.fontColor.MUTED};
  background-color: ${({ theme }) => theme.backgroundColor.DARK};

  /* padding doesn't work properly with line-clamp, so this approximates padding */
  border: 5px solid transparent;

  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  overflow: hidden;
`;

export function DataframeMentionDetails({
  mention,
}: {
  mention: RichTextDataframeMentionData;
}): React.ReactElement {
  return (
    <Wrapper>
      <TitleRow renderAs="h2">
        <DataframeIcon />
        <TableName>{mention.name}</TableName>
      </TitleRow>
      <Columns>{mention.columns.join(", ")}</Columns>
    </Wrapper>
  );
}

export function TableMentionDetails({
  mention,
  regenButton,
  showInDataBrowser,
}: {
  mention: RichTextTableMentionData;
  showInDataBrowser?: (mention: RichTextTableMentionData) => void;
  regenButton?: JSX.Element;
}): React.ReactElement | null {
  const { dataBrowserText } = useTerminology();

  return (
    <Wrapper>
      <SchemaPath>
        {[mention.databaseName, mention.schemaName]
          .filter((x) => !!x)
          .join(" / ")}
      </SchemaPath>
      <TitleRow renderAs="h2">
        <TableCellIcon />
        <TableName>{mention.tableName}</TableName>
        {mention.pinned && <PinnedTableIcon />}
        {mention.status && <StyledStatusLabel status={mention.status} />}
      </TitleRow>
      <Description>{mention.description}</Description>
      {regenButton ? (
        <>
          {regenButton}
          {/* mouseDown is important, because it fires before the MagicInput blurs and hides the mention autocomplete */}
          <HexTooltip content={`Show in ${dataBrowserText}`} placement="top">
            <HexButton
              icon={<EyeOpenIcon />}
              minimal={true}
              small={true}
              onMouseDown={() => showInDataBrowser?.(mention)}
            />
          </HexTooltip>
        </>
      ) : (
        <>
          {/* mouseDown is important, because it fires before the MagicInput blurs and hides the mention autocomplete */}
          <HexButton
            small={true}
            onMouseDown={() => showInDataBrowser?.(mention)}
          >
            Show in {dataBrowserText}
          </HexButton>
        </>
      )}
    </Wrapper>
  );
}

export function PinnedTableIcon() {
  const theme = useTheme();

  return (
    <HexTooltip content="Favorite table">
      <StarActiveIcon color={theme.userActionColor} />
    </HexTooltip>
  );
}
